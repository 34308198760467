/* You can add global styles to this file, and also import other style files */

:root {
  --text-primary  : rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.60);
  --text-disabled : rgba(0, 0, 0, 0.38);

  --primary-main      : #0066B2;
  --primary-background: rgba(0, 102, 178, 0.08);
  --primary-border    : rgba(0, 102, 178, 0.50);

  --secondary-main: #00838F;

  --warning-border  : rgb(255, 224, 128);
  --warning-darkText: rgb(102, 77, 0);

  // common
  --white-color  : #fff;
  --grey-300     : rgba(224, 224, 224, 1);
  --grey-grey-300: #e0e0e0;
  --grey-grey-400: #bdbdbd;
  --grey-grey-600: #757575;


  // material
  --mat-sidenav-container-width           : 20rem;
  --mat-sidenav-container-background-color: var(--white-color);
  --mat-sidenav-container-elevation-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  --mat-sidenav-content-background-color  : #f1f1f1;

  --mdc-filled-button-container-shape     : 4px;
  --mdc-filled-button-label-text-transform: uppercase;
  --mdc-filled-button-container-height    : 36px;

  --mdc-protected-button-container-shape     : 4px;
  --mdc-protected-button-label-text-transform: uppercase;
  --mdc-protected-button-container-height    : 36px;

  --mdc-outlined-button-container-shape     : 4px;
  --mdc-outlined-button-label-text-transform: uppercase;
  --mdc-outlined-button-outline-color       : var(--grey-grey-300);
  --mdc-outlined-button-container-height    : 36px;


  --mdc-elevated-card-container-shape    : 8px;
  --mdc-elevated-card-container-color    : var(--white-color);
  --mdc-elevated-card-container-elevation: none;

  --mat-table-background-color              : transparent;
  --mat-paginator-container-background-color: transparent;
  --mat-table-row-item-outline-color        : transparent; //#e0e0e0
  --mat-table-header-headline-color         : var(--text-primary);

  --mat-stepper-container-color               : transparent;
  --mat-stepper-line-color                    : var(--grey-grey-400);
  --mat-stepper-header-height                 : 62px;
  --mat-stepper-header-hover-state-layer-color: transparent;
  --mat-stepper-header-focus-state-layer-color: transparent;
  --mat-stepper-header-icon-background-color  : var(--text-disabled);
  --mat-stepper-header-label-text-color       : var(--text-disabled);

  --mdc-dialog-container-shape             : 4px;
  --mat-dialog-headline-padding            : 12px 16px 12px 24px;
  --mat-dialog-with-actions-content-padding: 24px 24px;
  --mat-dialog-content-padding             : 24px 24px;
  --mat-dialog-actions-padding             : 24px 24px;
  --mdc-dialog-subhead-color               : var(--text-primary);
  --mdc-dialog-subhead-size                : 1.3rem;
  --mdc-dialog-subhead-line-height         : 1.6rem;
  --mdc-dialog-subhead-weight              : 700;

  --mdc-chip-container-shape-radius: 4px;
}

html,
body {
  height: 100%;
}

body {
  margin     : 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* ::-webkit-scrollbar {
  width : 7px !important;
  height: 7px !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background   : var(--text-secondary);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-primary);
}

.w-100 {
  width: 100%;
}

.click-event {
  cursor: pointer;
}

.a-link {
  color : var(--primary-main);
  cursor: pointer;

  &:hover {
    color: var(--text-primary);
  }
}

.readOnly-field {
  pointer-events: none;

  .mat-mdc-text-field-wrapper {
    background-color: rgba($color: #f1f1f1, $alpha: .5) !important;
  }

  .mdc-floating-label {
    pointer-events: none !important;
  }

  .mat-mdc-select-arrow {
    &:before {
      content   : '' !important;
      display   : block;
      background: url('/icons/lock.svg') 0 0 no-repeat;
      width     : 21px;
      height    : 21px;
    }
  }

  .mat-mdc-chip-remove {
    opacity   : 0;
    visibility: hidden;
    width     : 0 !important;
    padding   : 6px !important;
  }

  &.auto-search-wrapper {
    .mat-icon {
      &:before {
        content   : '' !important;
        display   : block;
        background: url('/icons/lock.svg') 0 0 no-repeat;
        width     : 21px;
        height    : 21px;
      }
    }
  }
}

/* Material table style */
.table-style {

  .mat-mdc-header-row {
    background-color: var(--white-color);

    .mat-mdc-header-cell {
      font-weight: 500;
      box-shadow : 0 -1px 0 0 #E0E0E0 inset;
      white-space: nowrap;
    }
  }

  .mat-mdc-row {
    &:hover {
      background-color: rgba(0, 0, 0, .01);
    }

    .mat-mdc-cell {
      box-shadow: 0 -1px 0 0 #E0E0E0 inset;
    }
  }

  .noData {
    padding: 16px;
  }
}

/* Material Stepp Style */
.mat-horizontal-stepper-header {
  flex-direction: column;
  gap           : 16px;
  padding       : 16px 24px !important;
  pointer-events: none;

  .mat-step-icon {
    margin-right: 0 !important;
  }
}

.mat-step-icon-state-edit {
  --mat-stepper-header-edit-state-icon-background-color: #4caf50 !important;
}

.mat-horizontal-stepper-header-container {
  background-color: var(--white-color);
}

.mat-horizontal-content-container {
  background-color: #f1f1f1;
  padding         : 0 24px !important; // 24px
  overflow-y      : auto !important;
  height          : calc(100vh - 278px);
  margin-top      : 24px;
}

.saveRequired .mat-horizontal-content-container {
  height: calc(100vh - 326px);
}

/* Material Form Style */
.form-field-wrapper {
  width: 100%;

  &.readOnly {
    .custom-form-field {
      .mat-mdc-text-field-wrapper {
        background-color: rgba($color: #f1f1f1, $alpha: .5);
      }

      .mdc-floating-label {
        pointer-events: none !important;
      }
    }
  }
}

.form-date-field-wrapper {
  display    : flex;
  align-items: center;
  gap        : 24px;
  align-self : stretch;
  white-space: nowrap;

  .date-label {
    font-size     : 16px;
    font-style    : normal;
    font-weight   : 400;
    color         : var(--text-primary);
    line-height   : 24px;
    letter-spacing: 0.15px;
    margin-top    : -14px;

    .required-marker {
      color: var(--mat-form-field-error-text-color);
    }
  }

  &.readOnly {
    .custom-form-field {
      .mdc-floating-label {
        pointer-events: none !important;
      }

      .mat-mdc-text-field-wrapper {
        background-color: rgba($color: #f1f1f1, $alpha: .5);
      }

      [matdatepickertoggleicon] {
        &:before {
          content   : '';
          display   : block;
          background: url('/icons/lock.svg') 0 0 no-repeat;
          width     : 21px;
          height    : 21px;
        }
      }
    }
  }
}

.custom-form-field {
  width: 100%;

  .mat-mdc-text-field-wrapper {
    --mat-icon-color                           : #9e9e9e;
    --mat-form-field-container-vertical-padding: 8px;
    --mat-form-field-container-height          : 40px;

    --mdc-filled-text-field-container-color             : rgba(0, 0, 0, 0.05);
    --mdc-filled-text-field-active-indicator-color      : rgba(0, 0, 0, 0.05);
    --mdc-filled-text-field-input-text-color            : #9e9e9e;
    --mdc-filled-text-field-hover-active-indicator-color: #9e9e9e;
    --mdc-filled-text-field-active-indicator-height     : 0;

    --mdc-outlined-text-field-outline-color   : rgba(0, 0, 0, 0.23);
    --mdc-outlined-text-field-input-text-color: var(--text-primary);

    border-radius: 4px;
  }

  .mat-mdc-form-field-infix {
    width: 100%;

    .mdc-text-field__input::placeholder {
      color: #9e9e9e;
    }
  }

  textarea {
    resize    : none;
    max-height: 96px !important;
    height    : 72px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .mat-mdc-form-field-required-marker {
    color: var(--mat-form-field-error-text-color);
  }

  .mat-mdc-select-arrow {
    width : 14px;
    height: 24px;

    svg {
      display: none;
    }

    &:before {
      font-family: 'Material Icons';
      font-size  : 24px;
      content    : "\e313";
      display    : inline-block;
    }
  }

  [matdatepickertoggleicon] {
    height     : 21px;
    font-size  : 18px;
    line-height: 21px;
  }

  .mat-icon svg {
    width : 20px;
    height: 20px;
    margin: 1px;
  }
}

.radio-buttons-container {
  display    : flex;
  align-items: center;
  gap        : 24px;

  font-size     : 16px;
  font-style    : normal;
  font-weight   : 400;
  color         : var(--text-primary);
  line-height   : 24px;
  letter-spacing: 0.15px;

  .required-marker {
    color: var(--mat-form-field-error-text-color);
  }

  .mat-mdc-radio-group {
    display    : flex;
    align-items: center;
    gap        : 24px;
  }
}

/* Material Dialog Style */
.mat-mdc-dialog-container {
  .mat-mdc-dialog-title {
    display      : flex;
    align-items  : center;
    align-self   : stretch;
    border-bottom: 1px solid #e0e0e0;
    height       : 72px;
    margin       : 0;

    &:after {
      height: auto;
    }

    .mat-mdc-icon-button {
      margin-left: auto;
    }

    +.mat-mdc-dialog-content {
      padding-top: 24px !important;
    }
  }
}

.mat-mdc-dialog-content {
  max-height: 77vh !important;
}

.mat-mdc-dialog-actions {
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
  gap            : 16px;

  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;

  .mdc-button {
    --mdc-outlined-button-outline-color: var(--primary-border);
    margin                             : 0 !important;
  }
}

.mat-mdc-select-panel.isAddOption {
  max-height    : 265px;
  margin-bottom : 46px;
  padding-bottom: 0;
}

.add-newOption {
  position        : absolute;
  bottom          : 0;
  background-color: #fff;
  width           : 100%;
  padding         : 8px 16px;
  box-shadow      : var(--mat-select-container-elevation-shadow);
  background-color: var(--mat-select-panel-background-color);
  border-radius   : 0 0 4px 4px;

  .opt_text {
    cursor       : pointer;
    display      : inline-block;
    padding      : 4px 8px;
    border-radius: 4px;

    &:hover {
      background-color: var(--mat-option-focus-state-layer-color);
      outline         : 0;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content         : "";
  box-sizing      : border-box;
  position        : absolute;
  top             : 50%;
  left            : 50%;
  width           : 20px;
  height          : 20px;
  margin-top      : -10px;
  margin-left     : -10px;
  border-radius   : 50%;
  border          : 2px solid var(--white-color);
  border-top-color: var(--primary-main);
  animation       : spinner 0.8s linear infinite;
}

/* Form Field */
app-input-form-field,
app-textarea-form-field,
app-datepicker-form-field,
app-select-form-field,
app-select-search-form-field,
app-chips-search-form-field,
app-auto-complete-search-form-field {
  width: 100%;
}

.search-loader-spinner {
  position: absolute !important;
  top     : 9px !important;
  right   : -6px !important;
}

/* Page Style */
.page-loader {
  position       : absolute;
  top            : 0;
  left           : 0;
  right          : 0;
  z-index        : 999999;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 100%;
  height         : 100%;

  &::before {
    content         : '';
    display         : block;
    position        : absolute;
    top             : 0;
    left            : 0;
    right           : 0;
    width           : 100%;
    height          : 100%;
    background-color: rgba(0, 0, 0, .1);
  }
}

/* Notification */
.top-success-snackbar {
  &.mat-mdc-snack-bar-container {
    margin-top  : 80px;
    margin-right: 20px;

    .mdc-snackbar__surface {
      --mdc-snackbar-container-color      : rgb(10, 169, 26);
      --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 1);
      --mat-snack-bar-button-color        : rgba(255, 255, 255, 1);
      border-radius                       : 4px;
      box-shadow                          : none;
      max-width                           : 650px;
      min-width                           : 230px;

      .mdc-snackbar__label {
        display    : flex;
        align-items: center;
        gap        : 8px;
        align-self : stretch;

        &:before {
          font-family: 'Material Icons';
          font-size  : 20px;
          color      : #fff;
          content    : "\e86c";
          display    : inline-block;
        }
      }
    }
  }
}

.top-error-snackbar {
  &.mat-mdc-snack-bar-container {
    margin-top  : 80px;
    margin-right: 20px;

    .mdc-snackbar__surface {
      --mdc-snackbar-container-color      : rgba(249, 57, 57, 1);
      --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 1);
      --mat-snack-bar-button-color        : rgba(255, 255, 255, 1);
      border-radius                       : 4px;
      box-shadow                          : none;
      max-width                           : 650px;
      min-width                           : 230px;

      .mdc-snackbar__label {
        display    : flex;
        align-items: center;
        gap        : 8px;
        align-self : stretch;

        max-width  : 285px;
        word-wrap  : break-word;
        white-space: normal;

        &:before {
          font-family: 'Material Icons';
          font-size  : 20px;
          color      : #fff;
          content    : "\e5c9";
          display    : inline-block;
        }
      }

    }
  }
}

.top-warning-snackbar {
  &.mat-mdc-snack-bar-container {
    margin-top  : 80px;
    margin-right: 20px;

    .mdc-snackbar__surface {
      --mdc-snackbar-container-color      : rgba(255, 193, 7, 1);
      --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 1);
      --mat-snack-bar-button-color        : rgba(255, 255, 255, 1);
      border-radius                       : 4px;
      box-shadow                          : none;
      max-width                           : 650px;
      min-width                           : 230px;

      .mdc-snackbar__label {
        display    : flex;
        align-items: center;
        gap        : 8px;
        align-self : stretch;

        max-width  : 285px;
        word-wrap  : break-word;
        white-space: normal;

        &:before {
          font-family: 'Material Icons';
          font-size  : 20px;
          color      : #fff;
          content    : "\e002";
          display    : inline-block;
        }
      }

    }
  }
}

.userName-container {
  display    : flex;
  align-items: center;
  gap        : 6px;

  .user-key {
    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 30px;
    height          : 30px;
    border-radius   : 30px;
    background-color: var(--primary-main);
    color           : #fff;
  }
}